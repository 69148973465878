<template>

  <div class="my-box" v-loading="loading">
    <!-- 功能区域 -->
    <el-row>
      <el-card shadow="always" class="topS">
        <el-form label-width="70px">
          <el-row :gutter="10" style="margin-bottom: 0px;">
            <el-col :span="6">
              <el-form-item label="品牌">
                <el-select
                  v-model="seekData.brandId"
                  @change="selectOne"
                   clearable
                        filterable 
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item,index) in brandSelectData"
                    :key="index"
                    :label="item.brandName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- @change="selectTwo" -->
            <el-col :span="6">
              <el-form-item label="门店">
                <el-select
                  v-model="seekData.hotelId"
                   clearable
                        filterable 
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item,index) in hotelSelectData"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="季节模式">
                <el-select v-model="seekData.season" clearable placeholder="请选择">
                  <el-option
                    v-for="(item,index) in seasonData"
                    :key="index"
                    :label="item.lable"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-button 
                type="primary" 
                :loading="loadings" 
                v-if="seekData.hotelId != null && seekData.brandId != null && seekData.season != null" 
                @click="submit">设置季节模式</el-button>
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="reset">重置</el-button>
            </el-col>
          </el-row>
         
        </el-form>
        <!-- 按钮行 -->
        <el-row class="flex-row">
          <el-col :span="19">
            <el-button @click="setModeDialog('summer')">夏季模式</el-button>
            <el-button @click="setModeDialog('winter')">冬季模式</el-button>
            <span style="font-size: 14px; margin: 0 10px;">夏季模式: {{ summer }}</span>
            <span style="font-size: 14px; margin: 0 10px">冬季模式: {{ winter }}</span>
          </el-col>
          <el-col :span="5" class="reset-button">
          </el-col>
        </el-row>
        <!-- 进度条 -->
        <el-progress :percentage="percentage" :color="customColor" style="margin-top: 20px;"></el-progress>
      </el-card>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always">
        <div class="my-header-table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" disabled width="55"></el-table-column>
            <el-table-column prop="brandName" label="品牌"></el-table-column>
            <el-table-column prop="hotelName" label="门店"></el-table-column>
            <el-table-column prop="buildingName" label="建筑"></el-table-column>
            <el-table-column prop="floorName" label="楼层"></el-table-column>
            <el-table-column prop="roomNumber" label="房间号"></el-table-column>
            <el-table-column prop="repaired" label="季节模式">
              <template slot-scope="scope">
                <!-- <span>{{ scope.row.seasonalPattern }}</span> -->
                <el-tag v-if="scope.row.seasonal == 'winter'" type="danger">冬季模式</el-tag>
                <el-tag v-else-if="scope.row.seasonal == 'summer'">夏季模式</el-tag>
                <el-tag v-else type="info">未设置</el-tag>
              </template>
            </el-table-column>
          </el-table>
         
        </div>
      </el-card>
    </el-row>

  

    <!-- 密码确认框 -->
    <el-dialog
      title="密码确认框"
      :visible.sync="pwdDialogVisible"
      width="30%">
      <div class="flex-row">
        <span style="display: inline-block;width: 120px;line-height: 40px;">用户密码： </span>
        <el-input placeholder="请输入密码" size="large" v-model="password" show-password></el-input>
        <el-button 
          type="primary" 
          :disabled="password == null || password == ''" 
          size="mini" 
          style="margin-left: 10px;"
          @click="submitPassword">确认</el-button>
      </div>
      
    </el-dialog>
  </div>
</template>
<script>
  import { 
    setSeasonal, 
    getBrandSelect, 
    seasonalControlReq ,
    seasonalControlReq2,
    checkPassWord,
    getHotelSelect
  } from '@/api';

  export default {
    // name:"sbms",
    data () {
      return {
        percentage: 0,
        customColor: '#409eff',
        myInterval: null,
        summer: null,
        winter: null,
        loadings: false,
        rules: {
          season: [
            { required: true, message: '请选择季节模式', trigger: 'change' }
          ],
        },
        form: {
          season: null,
        },
        formLabelWidth: '120px',
        dialogVisible: false,
        seasonData: [{
          lable:'冬季模式',
          value: 'winter'
        }, {
          lable: '夏季模式',
          value: 'summer'
        }],
        password: null, // 密码输入框
        pwdDialogVisible: false,
        season: null, // 设置季节模式
        loading: false,
        seekData: {
          hotelId: null,
          brandId: null,
          season: null
        },
        allSelectData: [],
        brandSelectData: null,
        hotelSelectData: null,
        tableData: [],
        multipleSelection: [],
        height:null,
      }
    },
    beforeMount() {
      let h=document.documentElement.clientHeight || document.body.clientHeight
      this.height=h-300
    },
    watch: {
      percentage: {
        handler (newVal, oldVal) {
          if (newVal === 100) {
            clearInterval(this.myInterval)
            this.getInitData()
            this.loadings = false
          } 
        },
        deep: true
      },
    },
    methods: {
      setMode (season) {
        let ids = []
        this.multipleSelection.forEach(item => {
          ids.push(item.mainboardId)
        }) 
        this.mqcReq({ids, season,source:'web' })
       
      },
      mqcReq(data){
         seasonalControlReq(data).then(res => {
          console.log(res)
         
          if(res.status === 200 && res.data.code == '000000'){
            this.$message({
              type: 'info',
              message: '设置中...',
              duration: 30000
            })
             this.percentage =0
             this.myInterval = window.setInterval(() => {
              setTimeout(() => {
                this.percentage += 20
                if (this.percentage > 100) {
                  this.percentage = 100
                }
                 this.getInitData() //调用接口的方法
              }, 1)
            }, 6000);
          } else {
            this.$message({
              type: 'error',
              message: '设置失败！！'
            })
          }
          this.dialogVisible = false
        })
      },
      setNewMode () {
        let obj = Object.create(null)
        obj['brandId'] = this.seekData.brandId
        obj['hotelId'] = this.seekData.hotelId
        obj['season'] = this.seekData.season
        obj.source = 'web'
        this.loadings = true
        seasonalControlReq2(obj)
        .then(res => {
          console.log(res)
          this.dialogVisible = false
          if(res.status === 200 && res.data.code == '000000'){
            this.$message({
              type: 'info',
              message: '设置中...'
            })
            this.percentage =0
            this.myInterval = window.setInterval(() => {
              setTimeout(() => {
                this.percentage += 20
                if (this.percentage > 100) {
                  this.percentage = 100
                }
                     this.getInitData() //调用接口的方法
              }, 1)
            }, 6000);
          }  else {
            this.$message({
              type: 'error',
              message: '设置失败！！'
            })
          }
        })
      },
      setModeDialog(season) {
        if (!this.multipleSelection.length) {
          this.$message.warning('请选择设置模式的数据')
          return
        }
        this.season = season
        this.pwdDialogVisible = true
      },
      // 提交密码
      submitPassword () {
          let data={
            username: JSON.parse(sessionStorage.getItem('user')).username,
              password: this.password
          }
          checkPassWord(data).then(res => {
            console.log(res)
            if (res.data.code == '000000') {
              if (this.season) {
                this.setMode(this.season)
              } else {
                this.setNewMode()
              }
            } else {
              this.$message.error(res.data.message)
            }
            this.pwdDialogVisible = false
            this.password = null
            this.season = null
          })
      },
      set () {
        if (!this.multipleSelection.length) {
          this.$message.warning('请选择设置模式的数据')
          return
        } 
        this.dialogVisible = true
      },
     
      resetForm(formName) {
        this.dialogVisible = false
        this.$refs[formName].resetFields();
      },

      submit(){
        this.percentage = 0
        console.log(this.seekData)
        if(this.seekData.brandId == null || this.seekData.hotelId == null){
          this.$message({
            type: 'info',
            message: '请选择品牌或门店'
          })
          return
        } 
        if (this.seekData.season == null) {
          this.$message({
            type: 'info',
            message: '请选择季节模式'
          })
          return
        }
        // this.season = this.seekData.season
        this.pwdDialogVisible = true
        
      },

      // 获取品牌
      initialize () {
        getBrandSelect().then((res) => {
          if (res.status === 200) {
            this.brandSelectData = res.data.data
            this.seekData.brandId = this.brandSelectData[0].id;
            getHotelSelect(this.seekData.brandId).then((hotelRes) => {
              this.hotelSelectData = hotelRes.data.data;
              this.seekData.hotelId = this.hotelSelectData[0].id;

              this.getInitData()

            })
          }
        })
      },

 
      


      // 获取门店下拉框
      selectOne (id) {
        
        getHotelSelect(id).then((hotelRes) => {
          this.hotelSelectData = hotelRes.data.data;
          this.seekData.hotelId = this.hotelSelectData[0].id;
        })
        
      },
     

      defaultData() {
        this.brandSelectData = this.getSelectData(this.allSelectData, 0)
        if(this.brandSelectData.length) {
          this.seekData.brandId = this.brandSelectData[0].id
          // this.getRoomType(this.seekData.brandId)
        }
        this.hotelSelectData = this.getSelectData(this.allSelectData, 1, this.seekData.brandId)
        if(this.hotelSelectData.length) {
          this.seekData.hotelId = this.hotelSelectData[0].id
        }
        // this.getInitData()
      },
      getSelectData(arr, level, id) {
        let result = []
        // 品牌
        this.allSelectData.forEach((item, index) => {
          if(level == 0) {
            result.push({brandName:item.name, id: item.id})
          }
          if(id != undefined) {
            // 门店
            if(item.word.length) {
              if(level == 1 && id == item.id) {
                result = item.word
              }else if(level == 2) {
                
                item.word.forEach((item1, index1) => {
                  console.log(item1)
                  if(item1.id == id){
                    // 建筑
                    item1.word.forEach((item2, index2) => {
                      result.push({buildingName: item2.name, id: item2.id})
                    })
                  }
                })
              }
            }
          }
        })
        return result
      },
      getInitData () {
        this.loading = true
        setSeasonal(this.seekData).then(res => {
          // console.log(res.data.seasonalList)
          this.loading = false
          if(res.data.data.dropdown && res.data.data.dropdown.length) {
            this.allSelectData = res.data.data.dropdown
            this.defaultData()
          }
          this.tableData = res.data.data.seasonalList
          this.summer = res.data.data.summer
          this.winter = res.data.data.winter
        })
      },
      // 查询按钮
      handleSearch () {
        // console.log(this.seekData)
        this.seekData.pageNum = 1
        if (this.seekData) {
          this.getInitData()
        } else {
          this.getInitData()
        }
      },
      // 重置按钮
      reset () {
        this.seekData.hotelId = null,
        this.seekData.brandId = null,
        this.seekData.season = null,
        this.seekData.buildingId = null,
        this.seekData.floorId = null,
        this.seekData.roomNumber = null,
        this.seekData.mainBoardId = null,
        this.seekData.repaired = null,
        this.seekData.type = null,
        this.hotelSelectData = null
        this.getInitData()
      },
      //分页
      handleCurrentChange (cpage) {
        this.seekData.pageNum = cpage;
        this.getInitData(this.seekData)
      },
      handleSizeChange (psize) {
        // this.seekData.pageSize = psize;
        this.seekData.pageNum = 1
        this.getInitData(this.seekData)
      },
      handleSelectionChange (val) {
        this.multipleSelection = val;
        console.log(val)
      },
    },
    created() {
      this.initialize()
    },
    components: {
    }
  }
</script>
<style lang="scss">
.box {
  position: relative;
  max-width: 750px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 2px dashed #409EFF;
  margin: 50px auto;
  .modeType {
    position: absolute;
    top: -20px;
    font-size: 18px;
    background: #fff;
    padding: 8px 10px;
  }
  .el-row {
    margin-top: 10px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }

  .grid-content {
    border-radius: 4px;
    height: 36px;
    white-space: nowrap;
    .el-input__inner {
      max-width: 190px;
    }
  }
  .sb_btns {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    .radio {
      flex: 4;
      text-align: center;
    }
    .confirm_btn {
      flex: 1;
      text-align: center;
    }
  }

}
</style>

<style scoped>
.flex-row {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
}
</style>
